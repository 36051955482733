<template>
    <CCard>
        <CCardHeader class="page-header">
            <strong> {{ $t('common.menu.settings.general') }}</strong>
            <back-button></back-button>
        </CCardHeader>
        <CCardBody>
            <b-tabs lazy content-class="mt-3">
                <b-tab v-for="(tab,index) in tabs"
                       :key="tab.key"
                       v-if="tab.enabled"
                       :active="activeTab==tab.key"
                >
                    <template slot="title">
                        <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                        {{ tab.title }}
                        <b-badge v-if="tab.new" href="#" variant="warning">New</b-badge>
                    </template>
                    <component v-bind:is="tab.tab"></component>
                </b-tab>
            </b-tabs>
        </CCardBody>
    </CCard>
</template>

<script>
import {mapGetters} from 'vuex'

const TabGeneral = () => import('./general')
const TabProfiles = () => import('./profiles')
const TabDumps = () => import('./dumps')
const TabVats = () => import('./vats')
const TabOnedrive = () => import('./onedrive')
const TabBank = () => import('./bank')
const TabBatabase = () => import('./database')
const TabNewsTemplates = () => import('./news_templates')

export default {
    name: 'SettingsGeneral',
    data() {
        return {
            tabs: [
                {
                    'key': 'general',
                    'title': this.$t('settings.title.general'),
                    'icon': 'compress',
                    'tab': TabGeneral,
                    'enabled': true,
                },
                {
                    'key': 'profiles',
                    'title': this.$t('settings.title.profiles'),
                    'icon': 'user',
                    'tab': TabProfiles,
                    'enabled': true,
                },
                {
                    'key': 'vats',
                    'title': this.$t('settings.title.vats'),
                    'icon': 'percent',
                    'tab': TabVats,
                    'enabled': true,
                },
                {
                    'key': 'dumps',
                    'title': this.$t('settings.title.dumps'),
                    'icon': 'hdd',
                    'tab': TabDumps,
                    'enabled': true,
                },
                {
                    'key': 'onedrive',
                    'title': this.$t('settings.title.onedrive'),
                    'icon': 'cloud',
                    'tab': TabOnedrive,
                    'enabled': true,
                },
                {
                    'key': 'bank',
                    'title': this.$t('settings.title.bank'),
                    'icon': 'credit-card',
                    'tab': TabBank,
                    'enabled': true,
                },
                {
                    'key': 'database',
                    'title': this.$t('settings.title.database'),
                    'icon': 'database',
                    'tab': TabBatabase,
                    'enabled': true,
                },
            ],
            activeTab: 'general',
        }
    },
    mounted() {
        if (localStorage.getItem('firstLogin') === 'true') {
            this.activeTab = 'profiles'
        }
        this.shown()
    },
    methods: {
        ...mapGetters('CommonData', ['getCommonData']),
        shown() {
            if (this.getCommonData().domain == 'test' || this.getCommonData().domain == 'maintenance') {
                this.tabs.push({
                    'key': 'news_tamplates',
                    'title': this.$t('settings.title.news_templates'),
                    'icon': 'newspaper',
                    'tab': TabNewsTemplates,
                    'enabled': true,
                });
            }
        },
    },
}
</script>